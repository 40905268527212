import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as routerActions from '../../../redux/actions/router';
import * as profileActions from '../../../redux/actions/profile';

import { withHooks } from '../../../utils/withHooks';
import { callApi } from '../../../middlewares/api';

import { Alert } from 'reactstrap';

import Portfolio from '../../portfolio/Portfolio';
import PortfolioOverviewModal from '../../../components/portfolio/PortfolioOverviewModal';
import SkillbadgesSideMenu from '../../../components/skill-badges/SkillbadgesSideMenu';

class PortfolioWrapper extends Component {
  static propTypes = {
    params: PropTypes.object,
    profile: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    currentUserId: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      requestorDetails: false,
      visiblePortfolioOverviewModal: false
    };

    this.onDismissPortfolioOverview = this.onDismissPortfolioOverview.bind(this);
    this.togglePortfolioOverviewModal = this.togglePortfolioOverviewModal.bind(this);
  }

  componentWillMount() {
    const { actions, currentUserId, isAuthenticated } = this.props;

    actions.profileRequest(currentUserId, isAuthenticated);
  }

  componentDidMount() {
    this.getRequestorDetails();
  }

  onDismissPortfolioOverview() {
    this.props.actions.updateProfile(this.props.profile.id, { dismissPortfolioOverview: 1 });
  }

  togglePortfolioOverviewModal(event) {
    event.preventDefault();

    this.setState({ visiblePortfolioOverviewModal: !this.state.visiblePortfolioOverviewModal });
  }

  getRequestorDetails() {
    const userId = this.props?.params?.userId;

    if (userId) {
      callApi('', 'get', `user/user/${userId}`, true).then((response) => {
        this.setState({requestorDetails: response.data});
      });
    }
  }

  render() {
    let { profile, currentUserId } = this.props;

    if ( this.state.requestorDetails ) {
      profile = {...profile, requestorDetails: this.state.requestorDetails};
    }

    const { visiblePortfolioOverviewModal } = this.state;

    const isActivePortfolioOverview = profile && !!profile.dismissPortfolioOverview;

    return (
      <div>
        {(currentUserId === profile.id) &&
          <div className="container profile-share mt-4">
            <div className="row">
              <div className="col-lg-12">
                <Alert
                  color="info"
                  isOpen={!isActivePortfolioOverview}
                  toggle={this.onDismissPortfolioOverview}>
                  <div className="alert-content">
                    <div className="alert-content-left">
                      <h6 className="alert-heading">
                        Portfolio Overview
                      </h6>
                      <p>Learn more about the portfolio tool with this video</p>
                    </div>
                    <div className="alert-content-right">
                      <a
                        href="#"
                        className="btn btn-primary"
                        onClick={this.togglePortfolioOverviewModal}>
                        Watch Overview
                      </a>
                    </div>
                  </div>
                </Alert>
              </div>
            </div>
          </div>}

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content badges">
                <div className="row">
                  <div className="col-lg-12">
                    <SkillbadgesSideMenu profile={profile} />
                    {Object.keys(profile).length > 0 && <Portfolio profile={profile}/>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {visiblePortfolioOverviewModal &&
          <PortfolioOverviewModal
            isOpen={visiblePortfolioOverviewModal}
            toggleModal={this.togglePortfolioOverviewModal}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    profile: {
      isRequesting,
      data: profile,
    },
    auth: {
      data: {
        currentUser: { id: currentUserId }
      },
      isAuthenticated
    }
  } = state;

  return {
    profile,
    isRequesting,
    currentUserId,
    isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, routerActions, profileActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(PortfolioWrapper));
