import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateProfile } from '../../redux/actions/profile';

import { withHooks } from '../../utils/withHooks';

import { Helmet } from 'react-helmet';
import { Alert } from 'reactstrap';

import MyFeedback from './MyFeedback';
import FeedbackOverviewModal from '../../components/myfeedback/FeedbackOverviewModal';

class MyFeedbackWrapper extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    currentUserId: PropTypes.string.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      visibleFeedbackOverviewModal: false
    };

    this.onDismissFeedbackOverview = this.onDismissFeedbackOverview.bind(this);
    this.toggleFeedbackOverviewModal = this.toggleFeedbackOverviewModal.bind(this);
  }

  onDismissFeedbackOverview() {
    this.props.actions.updateProfile(this.props.profile.id, { dismissFeedbackOverview: 1 });
  }

  toggleFeedbackOverviewModal(event) {
    event.preventDefault();

    this.setState({ visibleFeedbackOverviewModal: !this.state.visibleFeedbackOverviewModal });
  }

  render() {
    const { profile, currentUserId } = this.props;
    const { visibleFeedbackOverviewModal } = this.state;

    const isActiveFeedbackOverview = profile && !!profile.dismissFeedbackOverview;

    return (
      <div>
        <Helmet title="My Feedback"/>

        {(profile.id === currentUserId) &&
          <div className="container profile-share mt-4">
            <div className="row">
              <div className="col-lg-12">
                <Alert
                  color="info"
                  isOpen={!isActiveFeedbackOverview}
                  toggle={this.onDismissFeedbackOverview}>
                  <div className="alert-content">
                    <div className="alert-content-left">
                      <h6 className="alert-heading">
                        Feedback Overview
                      </h6>
                      <p>Learn more about the feedback tool with this video</p>
                    </div>
                    <div className="alert-content-right">
                      <a
                        href="#"
                        className="btn btn-primary"
                        onClick={this.toggleFeedbackOverviewModal}>
                        Watch Overview
                      </a>
                    </div>
                  </div>
                </Alert>
              </div>
            </div>
          </div>}

        <MyFeedback/>

        {visibleFeedbackOverviewModal &&
          <FeedbackOverviewModal
            isOpen={visibleFeedbackOverviewModal}
            toggleModal={this.toggleFeedbackOverviewModal}/>}
      </div>
    );
  }
}

const mapStateToProps = ({
  profile: { data: profile },
  auth: { data: { currentUser: { id: currentUserId } } }
}) => ({ profile, currentUserId });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ updateProfile }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(MyFeedbackWrapper));
