import initialState from './initialState';

import {
  SKILLS_TO_CAREERS_INITIATIVE_GROUPS_REQUEST,
  SKILLS_TO_CAREERS_INITIATIVE_GROUPS_SUCCESS,
  SKILLS_TO_CAREERS_INITIATIVE_GROUPS_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.skillsToCareersInitiativeGroups, action) {
  switch (action.type) {
  case SKILLS_TO_CAREERS_INITIATIVE_GROUPS_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true
    });

  case SKILLS_TO_CAREERS_INITIATIVE_GROUPS_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      data: action.data._embedded.skills_to_careers_initiative
    });

  case SKILLS_TO_CAREERS_INITIATIVE_GROUPS_FAILURE:
    return Object.assign({}, state, {
      isRequesting: false,
      error: action.error
    });

  default:
    return state;
  }
}
