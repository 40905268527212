import { CALL_API } from '../../middlewares/api';

import {
  SKILLS_TO_CAREERS_INITIATIVE_GROUPS_REQUEST,
  SKILLS_TO_CAREERS_INITIATIVE_GROUPS_SUCCESS,
  SKILLS_TO_CAREERS_INITIATIVE_GROUPS_FAILURE,
} from '../../constants';

export const fetchSkillsToCareersInitiativeGroups = (groupName) => {
  const endpoint = `group/skills-to-careers-initiative?groupName=${groupName}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        SKILLS_TO_CAREERS_INITIATIVE_GROUPS_REQUEST,
        SKILLS_TO_CAREERS_INITIATIVE_GROUPS_SUCCESS,
        SKILLS_TO_CAREERS_INITIATIVE_GROUPS_FAILURE
      ],
    }
  };
};
