import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Helmet } from 'react-helmet';
import { withHooks } from '../../utils/withHooks';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

import SkillsToCareersSignUpForm from '../../components/auth/SkillsToCareersSignUpForm';

import Validator from '../../validator';

import PropTypes from 'prop-types';
import { isEmpty, isUndefined } from 'lodash';

import { requestSelfRegistration } from '../../redux/actions/registration';
import { fetchSkillsToCareersInitiativeGroups } from '../../redux/actions/skills-to-careers-initiative-groups';

let credentials = { email: '', confirmEmail: '' };

class OhioB2C extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    registration: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      popovers: [false, false, false, false, false,false, false, false, false, false],
      isOpenSignUpModal: false,
      displayAlertType: '',
      credentials: {
        email: '',
        confirmEmail: '',
        groupCode: ''
      },
      errors: {}
    };

    this.togglePopover1 = this.togglePopover1.bind(this);
    this.togglePopover2 = this.togglePopover2.bind(this);
    this.togglePopover3 = this.togglePopover3.bind(this);
    this.togglePopover4 = this.togglePopover4.bind(this);
    this.togglePopover5 = this.togglePopover5.bind(this);
    this.togglePopover6 = this.togglePopover6.bind(this);
    this.togglePopover7 = this.togglePopover7.bind(this);
    this.togglePopover8 = this.togglePopover8.bind(this);
    this.togglePopover9 = this.togglePopover9.bind(this);
    this.togglePopover10 = this.togglePopover10.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.toggleSignUpModal = this.toggleSignUpModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.dismissAlert = this.dismissAlert.bind(this);
  }

  componentWillMount(){
    document.body.classList.add('ohio-signup');

    const { location: { pathname } } = this.props;

    const groupName = pathname.slice(1);

    this.props
      .actions
      .fetchSkillsToCareersInitiativeGroups(groupName)
      .then(state => this.setState({
        credentials: {
          ...this.state.credentials,
          groupCode: state.skillsToCareersInitiativeGroups.data[0].inviteCode
        }
      }));
  }

  componentWillUnmount(){
    document.body.classList.remove('ohio-signup');
  }

  toggleSignUpModal(event) {
    event.preventDefault();

    this.setState({ isOpenSignUpModal: !this.state.isOpenSignUpModal });
  }

  isValid(field = null) {
    const { credentials, errors: prevErrors } = this.state;

    const validate = Validator.createValidator({
      email: ['required', 'email'],
      confirmEmail: ['required', 'email', 'match|email'],
      groupCode: ['required']
    }, credentials, field);

    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: { ...prevErrors, ...errors } });

    return isValid;
  }

  onChange(event) {
    const { target: { name, value } } = event;

    const credentials = { ...this.state.credentials, [name]: value };

    this.setState({ credentials }, () => this.isValid(name));
  }

  handleKeyPress(event) {
    if (event.key === 'Enter')
      return this.onSubmit(event);
  }

  onSubmit(event) {
    event.preventDefault();

    if (!this.isValid()) return false;

    this.props
      .actions
      .requestSelfRegistration(this.state.credentials)
      .then(() => {
        const { registration } = this.props;
        let displayAlertType = 'success';

        if ((! isEmpty(registration.error)) && (! isUndefined(registration.error.errorType))) {
          credentials = this.state.credentials;
          displayAlertType = (registration.error.errorType === 'DuplicateSelfRegistrationException') ? 'warning' : 'danger';
        }

        this.setState({ credentials, displayAlertType });
      })
      .catch((error) => {
        let displayAlertType = 'danger';

        if ((! isEmpty(error)) && (! isUndefined(error.errorType))) {
          displayAlertType = (error.errorType === 'DuplicateSelfRegistrationException') ? 'warning' : displayAlertType;
        }

        this.setState({ displayAlertType });
      });
  }

  dismissAlert() {
    this.setState({ displayAlertType: '' });
  }

  togglePopover1() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 0 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover2() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 1 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover3() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 2 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover4() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 3 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover5() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 4 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover6() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 5 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover7() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 6 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover8() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 7 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover9() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 8 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover10() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 9 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }

  handleLinkClick(event) {
    event.preventDefault();
  }

  render() {
    const imgTDnvde = require('../../assets/images/scr-logo.png');

    const { registration } = this.props;
    const {
      popovers,
      errors,
      credentials,
      isOpenSignUpModal,
      displayAlertType
    } = this.state;

    return (
      <div className="info" style={{paddingTop: 0, paddingBottom: 0, marginBottom: 0,marginTop:0}}>
        <Helmet title="CareerPrepped - Ohio State Board of Career Colleges and Schools"/>
        <article className="ohio_state_board">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h1>
                  Stand out. Get hired. Get ahead. <br />
                  CareerPrepped is free for <br />
                  Ohio students and job seekers.
                </h1>
              </div>
              <div className="col-lg-6">
                <img src={imgTDnvde} className="img-fluid" alt="Ohio State Board of Career Colleges and Schools" />
              </div>
            </div>
          </div>
        </article>
        <article className="section bluegrey">
          <div className="container">
            <div className="section-heading">
              <h1>Sign up today!</h1>
              <p>You get free lifetime access to CareerPrepped (normally $96/year).</p>
              <div className="talent-developers">
                <div className="get-started">
                  <a
                    href="#"
                    className="orange-btn toolkit"
                    onClick={this.toggleSignUpModal}>
                    Sign Up for Free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="section blue">
          <div className="container">
            <div className="section-heading">
              <h2 className="mb-0">CareerPrepped offers you a complete toolkit to achieve lifelong career success.</h2>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="section-container">
                  <div className="section-container-item order-sm-1 order-md-2">
                    <div className="section-bullets">
                      <ul className="fa-ul">
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover1"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Career Site</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[0]}
                            target="Popover1"
                            trigger="hover"
                            toggle={this.togglePopover1}>
                            <PopoverHeader>Career Site</PopoverHeader>
                            <PopoverBody>Don't settle for blending in. Say no to boring resumes and basic profiles. Set yourself apart from the crowd by showing off what makes you unique. Create your own personal Career Site with links to multiple pages to showcase your skills and achievements beyond your education and job history. It's time to make a statement and stand out!</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover2"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Resume Builder</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[1]}
                            target="Popover2"
                            trigger="hover"
                            toggle={this.togglePopover2}>
                            <PopoverHeader>Resume Builder</PopoverHeader>
                            <PopoverBody>With just a click, transform your Career Site into an interactive resume. It has clickable links to back up your skills with evidence. Edit it on the fly to save countless resumes customized for the job, company, or hiring manager you're targeting. Shine bright with a personalized resume for each job you go after.</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover3"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Job Match</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[2]}
                            target="Popover3"
                            trigger="hover"
                            toggle={this.togglePopover3}>
                            <PopoverHeader>Job Match</PopoverHeader>
                            <PopoverBody>Discover your dream job with our Job Match tool, powered by AI. It looks through thousands of job sites to find the perfect match for you based on your goals and preferences. Uncover exciting jobs you might not have known existed.</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover4"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Job Tracker</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[3]}
                            target="Popover4"
                            trigger="hover"
                            toggle={this.togglePopover4}>
                            <PopoverHeader>Job Tracker</PopoverHeader>
                            <PopoverBody>Keep all your job applications in one organized spot. Save the jobs you like, jot down notes, and keep contact details handy. Track your journey from applying to landing your dream job!</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover5"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Interview Prep Video Library</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[4]}
                            target="Popover5"
                            trigger="hover"
                            toggle={this.togglePopover5}>
                            <PopoverHeader>Interview Prep Video Library</PopoverHeader>
                            <PopoverBody>Get ready to ace your next interview! Watch videos to learn how to answer common interview questions like a pro. See examples of both weak and strong responses with helpful feedback. Get the tools you need to shine and turn your interview into a job offer!</PopoverBody>
                          </Popover>
                        </li>
                      </ul>

                      <ul className="fa-ul">
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover6"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Career Portfolio</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[5]}
                            target="Popover6"
                            trigger="hover"
                            toggle={this.togglePopover6}>
                            <PopoverHeader>Career Portfolio</PopoverHeader>
                            <PopoverBody>Unlock the power of your achievements! Build a digital treasure trove to showcase proof of your skills and accomplishments. With privacy controls and unlimited storage, you can store school projects, work samples, credentials, awards and more. Let your achievements speak louder than words on a resume!</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover7"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Skill Builders for Essential Skills</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[6]}
                            target="Popover7"
                            trigger="hover"
                            toggle={this.togglePopover7}>
                            <PopoverHeader>Skill Builders for Essential Skills</PopoverHeader>
                            <PopoverBody>Put a spotlight on your &quot;human skills&quot; - these are among the essential skills all employers crave. And they are in higher demand in today's AI-driven world. Get ahead with 40+ Skill Builders to boost your essentials skills and set yourself apart from your competition. These are the skills you need for lifelong career success, as reported by hundreds of industry surveys nationwide.</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover8"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Skill Badges for Essential Skills</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[7]}
                            target="Popover8"
                            trigger="hover"
                            toggle={this.togglePopover8}>
                            <PopoverHeader>Skill Badges for Essential Skills</PopoverHeader>
                            <PopoverBody>Claim Skill Badges to prove your essential skills with real evidence that others can review and endorse. Make sure employers know without a doubt that you have the skills research proves they're looking for but have a hard time finding in job candidates.</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover9"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Skill Feedback & Ratings</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[8]}
                            target="Popover9"
                            trigger="hover"
                            toggle={this.togglePopover9}>
                            <PopoverHeader>Skill Feedback & Ratings</PopoverHeader>
                            <PopoverBody>Get your claimed skills recognized through the power of feedback. CareerPrepped lets you provide evidence to prove each skill you have and then get feedback from community members and industry mentors on how well you’ve proven them. With feedback, learn your strengths and where you need to level up. Improve your skill evidence and increase your feedback ratings to showcase your proven skills to employers.</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover10"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Skill Endorsements & Testimonials</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[9]}
                            target="Popover10"
                            trigger="hover"
                            toggle={this.togglePopover10}>
                            <PopoverHeader>Skill Endorsements & Testimonials</PopoverHeader>
                            <PopoverBody>Get strong endorsements and testimonials from those who've seen your skills firsthand. Don't settle for weak praises from strangers. Verified endorsements, backed by proof, help you stand out and impress employers. Start collecting them and make your claimed skills more credible!</PopoverBody>
                          </Popover>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="section grey">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="text-center">The Ohio State Board of Career Colleges and Schools has facilitated the provision of lifetime free access to CareerPrepped for Ohio students and job seekers through its support for the Skills to Careers Initiative, a national, collective effort dedicated to fostering and advancing skills-based education and hiring.</p>
                <div className="talent-developers text-center">
                  <div className="get-started">
                    <a
                      href="#"
                      className="orange-btn toolkit"
                      onClick={this.toggleSignUpModal}>
                      Get Started Now!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>

        {this.state.isOpenSignUpModal &&
          <SkillsToCareersSignUpForm
            errors={errors}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            registration={registration}
            credentials={credentials}
            toggleSignUpModal={this.toggleSignUpModal}
            isOpenSignUpModal={isOpenSignUpModal}
            displayAlertType={displayAlertType}
            dismissAlert={this.dismissAlert}
            handleKeyPress={this.handleKeyPress}/>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  registration: state.registration
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    requestSelfRegistration,
    fetchSkillsToCareersInitiativeGroups
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(OhioB2C));
