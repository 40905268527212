import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import FileCardRenderer from './FileCardRenderer';
import SortAndSearch from '../../components/portfolio/SortAndSearch';

const PortfolioComponent = ({
  files,
  profile,
  skills,
  callingAPI,
  onSortChange,
  toggleDropdown,
  activeSort,
  searchValue,
  isDropdownOpen,
  isRequestingSort,
  onSearchChange,
  onSearchSubmit,
  isRequestingSearch,
  handleSearchFormKeyPress,
  onAddPortfolio,
  isOwner,
  onClearSearch
}) => {
  const noPortfolioFiles = files.data.length === 0;
  return (
    <div className="col-lg-12">
      {
        isOwner &&
          <div>
            <div>
              <p className="mb-4">
                Save portfolio files and link them to your Skill Badges and/or skills listed in your <Link to={`/cp/${profile.vanity}`}>Career Site</Link> as evidence of your skills. Check out <Link to="/skill-builders/develop-a-career-portfolio">Develop a Career Portfolio</Link> for tips on building your portfolio and watch this <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=gDW7wjdbJew">video overview</a> of the Career Portfolio.
              </p>
            </div>
            <div>
              <SortAndSearch
                noPortfolioFiles={noPortfolioFiles}
                activeSort={activeSort}
                isRequestingSort={isRequestingSort}
                isDropdownOpen={isDropdownOpen}
                onSortChange={onSortChange}
                toggleDropdown={toggleDropdown}
                searchValue={searchValue}
                onClearSearch={onClearSearch}
                onSearchChange={onSearchChange}
                onSearchSubmit={onSearchSubmit}
                onAddPortfolio={onAddPortfolio}
                handleSearchFormKeyPress={handleSearchFormKeyPress}
                isRequestingSearch={isRequestingSearch}/>
            </div>
          </div>
      }
      <div id="portfolio">
        <FileCardRenderer
          profile={profile}
          callingAPI={callingAPI}
          files={files}
          skills={skills}
          isOwner={isOwner}
          isRequestingSort={isRequestingSort}
          onAddPortfolio={onAddPortfolio}
          noPortfolioFiles={noPortfolioFiles}
          isRequestingSearch={isRequestingSearch}/>
      </div>
    </div>
  );
};

PortfolioComponent.propTypes = {
  callingAPI: PropTypes.bool,
  files: PropTypes.object,
  profile: PropTypes.object,
  onAddPortfolio: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  activeSort: PropTypes.string.isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
  isRequestingSort: PropTypes.bool.isRequired,
  isRequestingSearch: PropTypes.bool.isRequired,
  handleSearchFormKeyPress: PropTypes.func.isRequired,
  skills: PropTypes.array,
  isOwner: PropTypes.bool,
  onClearSearch: PropTypes.func.isRequired
};

export default PortfolioComponent;
