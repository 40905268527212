import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../../validator';

import * as experienceActions from '../../../redux/actions/experience';
import {updateMyCareerPreppedStatus, getMyCareerPreppedStatus} from '../../../redux/actions/my-careerprepped-status';

import Loading from '../../../components/common/Loading';
import ExperienceForm from '../../../components/profile/forms/ExperienceForm';
import ExperienceList from '../../../components/profile/sections/ExperienceList';
import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';
import formWithModal from '../../../components/profile/modal/formWithModal';
import { toastr } from 'react-redux-toastr';

class Experience extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    experience: PropTypes.object.isRequired,
    experiences: PropTypes.array.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    showVisibilitySelect: PropTypes.bool,
    onChangeVisibility: PropTypes.func,
    isEditingSections: PropTypes.bool,
    isEditing: PropTypes.bool,
    onCancelModal: PropTypes.func,
    onToggleSectionEdit: PropTypes.func,
    profile: PropTypes.object,
    isDeleting: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    isEditingFromModal: PropTypes.bool
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isEditing: ( (this.props.isEditing) ? this.props.isEditing : false ),
      isSubmitting: false,
      editingId: null,
      experience: Object.assign({}, this.props.experience),
      editorText: {description: this.props.experience.description}
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.onEditExperience = this.onEditExperience.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeSelectDropdown = this.onChangeSelectDropdown.bind(this);
  }

  componentDidMount() {
    const { profile } = this.props;
    this.props.actions.experienceRequest(profile.id);
  }

  formatEndDate = (dateString) => {
    return dateString === '' ? null : dateString;
  };

  isValid(field = null) {
    const noend = this.state.experience.noend;
    let rules = {};
    if (noend === true){
      rules = {
        experienceType: ['required'],
        careerCluster: ['required'],
        paid: ['required'],
        courseProgramAffiliated: ['required'],
        title: ['required', 'minLength|2', 'maxLength|100'],
        orgname: ['required', 'minLength|2', 'maxLength|100'],
        startdate: ['required', 'startdate'],
        orgurl: ['unsafeurl']
      };
    } else {
      rules = {
        experienceType: ['required'],
        careerCluster: ['required'],
        paid: ['required'],
        courseProgramAffiliated: ['required'],
        title: ['required', 'minLength|2', 'maxLength|100'],
        orgname: ['required', 'minLength|2', 'maxLength|100'],
        startdate: ['required','startdate'],
        enddate: ['required', 'enddate',`beforeStart|${this.formatEndDate(this.state.experience.startdate)}`],
        orgurl: ['unsafeurl']
      };
    }

    const { experience, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, experience, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    if (noend === true) {
      delete prevErrors['enddate'];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  isValidDescription(field = null) {
    const rules = {
      description: ['maxWords|200']
    };
    const { editorText, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  onChange(event) {
    let { name, value, type, checked } = event.target;

    value = type === 'checkbox' ? checked : value;

    const experience = { ...this.state.experience, [name]: value };

    this.setState({ experience }, () => this.isValid(name));

    if (name === 'startdate') {
      this.isValid('enddate');
    }
  }

  onChangeSelectDropdown(value, actionMeta) {
    const { name } = actionMeta;

    const experience = { ...this.state.experience, [name]: value };

    this.setState({ experience }, () => this.isValid(name));
  }

  onSave(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true });

    if (! this.isValid() || ! this.isValidDescription() ) {
      return this.setState({ isSubmitting: false });
    }

    const { experience, editingId } = this.state;

    if ((experience.courseProgramAffiliated.value === 'Yes') && (experience.reportedHours === '')) {
      return this.setState({
        isSubmitting: false,
        errors: { ...this.state.errors, reportedHours: 'Required' }
      });
    }

    const experienceData = {
      ...experience,
      experienceType: experience.experienceType.value,
      careerCluster: experience.careerCluster.value,
      paid: experience.paid.value,
      courseProgramAffiliated: experience.courseProgramAffiliated.value,
      reportedHours: (experience.reportedHours === '') ? 0 : experience.reportedHours
    };

    const { actions, experiences, isEditingFromModal, onCancelModal } = this.props;

    if(editingId !== null){
      const index = experiences.findIndex(element =>
        element.id === experience.id);

      return actions.updateExperience(index, experienceData)
        .then(() => {
          if ( isEditingFromModal ) {
            toastr.success('Experience added successfully');
            onCancelModal();
          }
          this.handleResponse();
        });
    }

    this.props.actions.saveExperience(experienceData)
      .then(async () => {
        await this.props.actions.updateMyCareerPreppedStatus();
        this.props.actions.getMyCareerPreppedStatus();
        if ( isEditingFromModal ) {
          toastr.success('Experience added successfully');
          onCancelModal();
        }
        this.handleResponse();
      });
  }

  onCancel() {
    this.setState({
      isEditing: false,
      errors: {},
      editingId: null,
      experience: this.props.experience
    }, () => this.props.onToggleSectionEdit('experience'));
  }

  onChangeDescription(value, ...params) {
    const { experience, editorText} = this.state;

    experience['description'] = value;
    editorText['description'] = params[2].getText().replace(/\r?\n|\r/gm, ' ');

    this.setState({ experience, editorText }, () => this.isValidDescription('description'));
  }

  onEditClick(event) {
    event.preventDefault();

    this.setState({ isEditing: true },
      () => this.props.onToggleSectionEdit('experience'));
  }

  onEditExperience(id){
    const { experiences } = this.props;
    const experienceObject = experiences.find(experience => experience.id === id);
    const experience = Object.assign({}, this.state.experience, experienceObject);
    this.setState({
      isEditing: true,
      experience,
      editingId: id
    }, () => this.props.onToggleSectionEdit('experience'));
  }

  onDelete(event) {
    event.preventDefault();

    const { experience } = this.state;
    this.props.actions.deleteExperience(experience.id)
      .then(async () => {
        this.handleResponse();
        await this.props.actions.updateMyCareerPreppedStatus();
        this.props.actions.getMyCareerPreppedStatus();
      });
  }

  handleResponse() {
    this.setState({
      isEditing: false,
      isSubmitting: false,
      experience: this.props.experience,
      editingId: null
    },() => this.props.onToggleSectionEdit('experience'));

  }

  EditingExperience = formWithModal(ExperienceForm);

  render() {
    const {
      experiences,
      isRequesting,
      isPublicProfile,
      showVisibilitySelect,
      onChangeVisibility,
      isEditingSections,
      isDeleting,
      isUpdating
    } = this.props;
    const { errors, experience, isEditing, isSubmitting, editingId } = this.state;

    if(experiences.length === 0 && isPublicProfile){
      return null;
    }

    return (
      <div className="profile-item experience">
        <ProfileCardHeader
          isEditing={isEditing}
          isEditingSections={isEditingSections}
          type="list"
          title="Experiences"
          onEditClick={this.onEditClick}
          isPublicProfile={isPublicProfile}
          showVisibilitySelect={showVisibilitySelect}
          onChangeVisibility={onChangeVisibility}
          icon="experience"/>

        {isRequesting ? (
          <Loading />
        ) : isEditing ? (
          <div className="profile-item__card">
            <this.EditingExperience
              errors={errors}
              experience={experience}
              isSubmitting={isSubmitting}
              isUpdating={isUpdating}
              isDeleting={isDeleting}
              showDelete={editingId !== null}
              onChange={this.onChange}
              onChangeSelectDropdown={this.onChangeSelectDropdown}
              onSave={this.onSave}
              onCancel={(this.props.onCancelModal) ? this.props.onCancelModal : this.onCancel}
              exampleType={'Experience'}
              onChangeDescription={this.onChangeDescription}
              onDelete={this.onDelete}/>
          </div>
        ) : (
          <ExperienceList
            experiences={experiences}
            onEdit={this.onEditExperience}
            isPublicProfile={isPublicProfile}/>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const experience = {
    title: '',
    orgname: '',
    orgurl: '',
    description: '',
    startdate: '',
    enddate: '',
    noend: false,
    experienceType: null,
    careerCluster: null,
    paid: null,
    courseProgramAffiliated: null,
    reportedHours: ''
  };

  return {
    experience,
    experiences: state.experiences.data,
    isRequesting: state.experiences.isRequesting,
    isDeleting: state.experiences.isDeleting.status,
    isUpdating: state.experiences.isUpdating.status
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...experienceActions,
      updateMyCareerPreppedStatus,
      getMyCareerPreppedStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Experience);
