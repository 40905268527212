import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import Alert from './Alert';
import TextInput from '../common/TextInput';

import { isEmpty, isUndefined } from 'lodash';

const SkillsToCareersSignUpForm = ({
  errors,
  onChange,
  onSubmit,
  credentials,
  registration,
  toggleSignUpModal,
  isOpenSignUpModal,
  displayAlertType,
  dismissAlert,
  handleKeyPress
}) => {
  let alertHeading = 'Thank you for signing up for CareerPrepped.';
  let alertMessage = 'You\'re almost finished! Please check your email to complete your account creation. We\'ve emailed you a link to setup your password and sign in. If you don\'t see the email in your inbox, please check any spam, junk, clutter, or promotions folders and search "CareerPrepped" in all email folders. If you still can\'t locate your email, contact support at careerprepped@maxknowledge.com and let us know you didn\'t get your sign up email.';

  if (! isUndefined(registration.error.errorType)) {
    alertHeading = 'There\'s a problem with the email entered.';
    alertMessage = registration.error.detail;

    if ((registration.error.errorType === 'DuplicateSelfRegistrationException')) {
      alertHeading = 'Duplicate registration requests.';
    }

    if ((registration.error.errorType === 'InvalidGroupCodeException')) {
      alertHeading = 'Invalid group code.';
    }
  }

  return (
    <Modal
      centered
      isOpen={isOpenSignUpModal}
      toggle={toggleSignUpModal}
      size={'md'}
      className="talent-signup-modal">
      <ModalHeader
        className="no-border"
        toggle={toggleSignUpModal}>
        Sign Up
      </ModalHeader>
      <ModalBody>
        <form onSubmit={onSubmit} onKeyPress={handleKeyPress}>
          <div className="row">
            {(! isEmpty(displayAlertType)) &&
              <Alert
                displayAlertType={displayAlertType}
                alertHeading={alertHeading}
                alertMessage={alertMessage}
                dismissAlert={dismissAlert}/>}
          </div>
          <div className="row">
            <div className="col-lg-6">
              <TextInput
                autoFocus
                name="email"
                placeholder="Email"
                error={errors.email}
                value={credentials.email}
                onChange={onChange}/>
            </div>
            <div className="col-lg-6">
              <TextInput
                name="confirmEmail"
                placeholder="Confirm Email"
                error={errors.confirmEmail}
                value={credentials.confirmEmail}
                onChange={onChange}/>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <TextInput
                name="groupCode"
                disabled
                placeholder="Group Code"
                error={errors.groupCode}
                value={credentials.groupCode}
                onChange={onChange}/>
            </div>
          </div>

          <div className="account_form_submit-cont" style={{textAlign: 'center'}}>
            <input
              type="submit"
              disabled={registration.isSubmitting}
              value={registration.isSubmitting ? 'Submitting...' : 'Submit'}
              className="btn btn-primary btn-xlg w-100"/>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

SkillsToCareersSignUpForm.propTypes = {
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  registration: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  isOpenSignUpModal: PropTypes.bool.isRequired,
  toggleSignUpModal: PropTypes.func.isRequired,
  displayAlertType: PropTypes.string.isRequired,
  dismissAlert: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired
};

export default SkillsToCareersSignUpForm;
