import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateProfile } from '../../redux/actions/profile';
import * as jobSearchActions from '../../redux/actions/job-search';

import { withHooks } from '../../utils/withHooks';

import { Helmet } from 'react-helmet';
import { Alert, Container, Row, Col } from 'reactstrap';
import { Link, Outlet } from 'react-router-dom';
import classNames from 'classnames';

import JobCenterOverviewModal from '../../components/jobs/JobCenterOverviewModal';

class JobsWrapper extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      visibleJobCenterOverviewModal: false
    };

    this.onDismissJobCenterOverview = this.onDismissJobCenterOverview.bind(this);
    this.toggleJobCenterOverviewModal = this.toggleJobCenterOverviewModal.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.resetJobSearch();
  }

  onDismissJobCenterOverview() {
    this.props.actions.updateProfile(this.props.profile.id, { dismissJobCenterOverview: 1 });
  }

  toggleJobCenterOverviewModal(event) {
    event.preventDefault();

    this.setState({ visibleJobCenterOverviewModal: !this.state.visibleJobCenterOverviewModal });
  }

  render() {
    const { location, profile } = this.props;
    const { visibleJobCenterOverviewModal } = this.state;

    const isActiveJobCenterOverview = profile && !!profile.dismissJobCenterOverview;

    return (
      <div>
        <Helmet title="Job Search"/>

        <div className="admin-navbar admin-panel" style={{ padding:16 }}>
          <Container>
            <Row>
              <Col lg="8" md="7">
                <h2 className="mb-0">Job Center</h2>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="admin-navbar">
          <Container>
            <Row>
              <Col lg="12">
                <Link
                  to="/job-center"
                  className={classNames({ active: location.pathname === '/job-center' })}>
                  Job Match
                </Link>
                {/* <Link
                  to="/job-center/job-search"
                  className={classNames({ active: location.pathname === '/job-center/job-search' })}>
                  Job Search
                  </Link> */}
                <Link
                  to="/job-center/job-tracker"
                  className={classNames({
                    active: location.pathname.includes('/job-tracker') ||
                      location.pathname.includes('/my-jobs')
                  })}>
                  Job Tracker
                </Link>
                <Link
                  to="/job-center/resume-builder"
                  className={classNames({
                    active: location.pathname.includes('/job-center/resume-builder')
                  })}>
                  Resume Builder
                </Link>
                <Link
                  to="/job-center/interview-videos"
                  className={classNames({
                    active: location.pathname.includes('/interview-videos') ||
                      location.pathname.includes('/interview-prep-videos')
                  })}>
                  Interview Videos
                </Link>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="container profile-share mt-4">
          <div className="row">
            <div className="col-lg-12">
              <Alert
                color="info"
                isOpen={!isActiveJobCenterOverview}
                toggle={this.onDismissJobCenterOverview}>
                <div className="alert-content">
                  <div className="alert-content-left">
                    <h6 className="alert-heading">
                      Job Center Overview
                    </h6>
                    <p>Learn more about using the Job Center with this video</p>
                  </div>
                  <div className="alert-content-right">
                    <a
                      href="#"
                      className="btn btn-primary"
                      onClick={this.toggleJobCenterOverviewModal}>
                      Watch Overview
                    </a>
                  </div>
                </div>
              </Alert>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <Outlet/>
          </div>
        </div>

        {visibleJobCenterOverviewModal &&
          <JobCenterOverviewModal
            isOpen={visibleJobCenterOverviewModal}
            toggleModal={this.toggleJobCenterOverviewModal}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ profile: { data: profile } }) => ({ profile });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...jobSearchActions, updateProfile }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(JobsWrapper));
