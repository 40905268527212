import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const TextInput = ({
  type,
  name,
  label,
  onChange,
  placeholder,
  value,
  error,
  inputId,
  styles,
  disabled,
  note,
  wrapperStyle,
  className,
  onKeyPress,
  redText,
  autoFocus,
  srOnly,
  formOnly,
}) => {
  let wrapperClass = 'form-group';
  let noteDanger = false ;

  if (error && error.length > 0) {
    wrapperClass += ' ' + 'has-danger';
    noteDanger = true;
  }

  let labelClassName = 'form-control-label';

  if (srOnly) {
    labelClassName = 'sr-only';
  }

  return (
    <div className={!formOnly && wrapperClass} style={wrapperStyle}>
      {label &&
        <label className={labelClassName}>
          {label}
          {redText &&
            <span className="text-danger" style={{ marginLeft: 4, fontSize: 12 }}>
              {`(${redText})`}
            </span>}
        </label>}

      {
        note &&
          <span className={classNames(
            'note',
            'pl-1',
            {[note]:note, required:noteDanger}
          )}>
            ({note})
          </span>
      }

      {/* <div className="field"> */}
      <input
        id={inputId}
        type={type || 'text'}
        name={name}
        style={styles}
        className={className || 'form-control'}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onKeyDown={onKeyPress}
        autoFocus={autoFocus}/>

      {error && <div className="form-control-feedback">{error}</div>}
      {/* </div> */}
    </div>
  );
};

TextInput.propTypes = {
  inputId: PropTypes.string,
  note: PropTypes.string,
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  name: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  styles: PropTypes.object,
  wrapperStyle: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onKeyPress: PropTypes.func,
  redText: PropTypes.string,
  autoFocus: PropTypes.bool,
  srOnly: PropTypes.bool,
  formOnly: PropTypes.bool
};

export default TextInput;
