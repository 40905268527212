import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { CSSTransition } from 'react-transition-group';

import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import QuillEditor from '../../common/QuillEditor';
import { SimpleToolTipWrapper } from '../../common/ToolTipWrapper';

import _isObject from 'lodash/isObject';
import _isUndefined from 'lodash/isUndefined';

const modules = {
  toolbar: [
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  }
};

const experienceTypeOptions = [
  { label: 'Apprenticeship', value: 'Apprenticeship'},
  { label: 'Cooperative Education (Co-op)', value: 'Cooperative Education (Co-op)' },
  { label: 'Internship', value: 'Internship' },
  { label: 'Job Shadow', value: 'Job Shadow' },
  { label: 'Employment', value: 'Employment' },
  { label: 'Externship', value: 'Externship' },
  { label: 'Mentorship', value: 'Mentorship' },
  { label: 'Military', value: 'Military' },
  { label: 'On-the-job Training (OJT)', value: 'On-the-job Training (OJT)' },
  { label: 'Personal Project', value: 'Personal Project' },
  { label: 'Practicum', value: 'Practicum' },
  { label: 'School-based Enterprise', value: 'School-based Enterprise' },
  { label: 'Self-Employed', value: 'Self-Employed' },
  { label: 'Service Learning Program', value: 'Service Learning Program' },
  { label: 'Simulated Workplace', value: 'Simulated Workplace' },
  {
    label: 'Supervised Agriculture Experience (School Site)',
    value: 'Supervised Agriculture Experience (School Site)'
  },
  {
    label: 'Supervised Agriculture Experience (Employer Site)',
    value: 'Supervised Agriculture Experience (Employer Site)'
  },
  { label: 'Temporary Contract', value: 'Temporary Contract' },
  { label: 'Transitional Job Program', value: 'Transitional Job Program' },
  { label: 'Volunteer', value: 'Volunteer' }
];

const careerClusterOptions = [
  {
    label: 'Agriculture, Food & Natural Resources',
    value: 'Agriculture, Food & Natural Resources'
  },
  { label: 'Architecture & Construction', value: 'Architecture & Construction' },
  {
    label: 'Arts, A/V Technology & Communications',
    value: 'Arts, A/V Technology & Communications'
  },
  {
    label: 'Business Management & Administration',
    value: 'Business Management & Administration'
  },
  { label: 'Education & Training', value: 'Education & Training' },
  { label: 'Finance', value: 'Finance' },
  {
    label: 'Government & Public Administration',
    value: 'Government & Public Administration'
  },
  { label: 'Health Science', value: 'Health Science' },
  { label: 'Hospitality & Tourism', value: 'Hospitality & Tourism' },
  { label: 'Human Services', value: 'Human Services' },
  { label: 'Information Technology', value: 'Information Technology' },
  {
    label: 'Law, Public Safety, Corrections & Security',
    value: 'Law, Public Safety, Corrections & Security'
  },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Marketing', value: 'Marketing' },
  {
    label: 'Science, Technology, Engineering & Mathematics',
    value: 'Science, Technology, Engineering & Mathematics'
  },
  {
    label: 'Transportation, Distribution & Logistics',
    value: 'Transportation, Distribution & Logistics'
  }
];

const selectOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
];

const transitionClasses = {
  enter: 'animated',
  enterActive: 'animate__fadeIn',
  exit: 'animated',
  exitActive: 'animate__fadeOut'
};

const ExperienceForm = ({
  experience,
  onChange,
  onSave,
  isDeleting,
  onCancel,
  onDelete,
  isSubmitting,
  isUpdating,
  errors,
  onChangeDescription,
  showDelete,
  onChangeSelectDropdown
}) => {
  const titleLabel = (
    <span>
      Title:
    </span>
  );

  if (_isObject(experience.startdate)) {
    experience.startdate = experience.startdate.date.replace(' 00:00:00.000000', '');
  }

  if (_isObject(experience.enddate)) {
    experience.enddate = experience.enddate.date.replace(' 00:00:00.000000', '');
  }

  const endDateNote = experience.noend ? '' : 'Required';

  const hasExperienceTypeError = errors && errors.experienceType && errors.experienceType.length > 0;

  const reportedHoursLabel = (
    <span>
      Reported Hours&nbsp;
      <SimpleToolTipWrapper
        id={'reported-hours-tooltip'}
        toolTipContent={
          <div
            style={{fontSize: 12}}
            className="inside-tooltip">
            Enter the number of hours you completed in this experience.
          </div>
        }>
        <small><i className="fa text-orange fa-question-circle"/></small>
      </SimpleToolTipWrapper>
    </span>
  );

  return (
    <div>
      <div className="row mb-2">
        <div className="col-lg-6 mb-2">
          <label className="form-control-label">Experience Type:</label>
          <span className={classNames(
            'note',
            'pl-1',
            'Required',
            {required: hasExperienceTypeError}
          )}>(Required)</span>
          <Select
            name="experienceType"
            placeholder="Select type that best fits"
            onChange={onChangeSelectDropdown}
            value={experience.experienceType}
            options={experienceTypeOptions}/>

          {errors.experienceType && <div className="text-danger">{errors.experienceType}</div>}
        </div>
        <div className="col-lg-6 mb-2">
          <label className="form-control-label">Career Cluster:</label>
          <span className={classNames(
            'note',
            'pl-1',
            'Required',
            {
              required: errors && errors.careerCluster && errors.careerCluster.length > 0
            }
          )}>(Required)</span>
          <Select
            name="careerCluster"
            placeholder="Select type that best fits"
            onChange={onChangeSelectDropdown}
            value={experience.careerCluster}
            options={careerClusterOptions}/>

          {errors.careerCluster && <div className="text-danger">{errors.careerCluster}</div>}
        </div>
        <div className="col-lg-6">
          <TextInput
            name="title"
            label={titleLabel}
            note="Required"
            placeholder="Enter experience title"
            error={errors.title}
            value={experience.title}
            onChange={onChange}/>

        </div>
        <div className="col-lg-6">
          <label className="form-control-label">Paid:</label>
          <span className={classNames(
            'note',
            'pl-1',
            'Required',
            {
              required: errors && errors.paid && errors.paid.length > 0
            }
          )}>(Required)</span>
          <Select
            name="paid"
            placeholder="Select type that best fits"
            onChange={onChangeSelectDropdown}
            value={experience.paid}
            options={selectOptions}/>

          {errors.paid && <div className="text-danger">{errors.paid}</div>}
        </div>
        <div className="col-lg-12">
          <TextInput
            name="orgname"
            label="Organization Name: "
            note="Required"
            placeholder="Enter your organization's name"
            error={errors.orgname}
            value={experience.orgname}
            onChange={onChange}/>

          <TextInput
            name="orgurl"
            label="Organization URL:"
            placeholder="Enter your organization's URL"
            error={errors.orgurl}
            value={experience.orgurl}
            onChange={onChange}/>
        </div>
        <div className="col-lg-12">
          <div
            className={classNames('statement-form-container', {
              'has-danger': errors.description
            })}>
            <label className="form-control-label">Description:</label>
            <QuillEditor
              modules={modules}
              placeholder="Description"
              onChange={onChangeDescription}
              value={experience.description} />

            {errors.description && <div className="text-danger">{errors.description}</div>}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="row mt-3">
            <div className="col-lg-6">
              <TextInput
                type="date"
                name="startdate"
                label="Start Date: "
                note="Required"
                placeholder="Start Date - YYYY/MM/DD"
                error={errors.startdate}
                value={experience.startdate}
                onChange={onChange}/>
            </div>
            <div className="col-lg-6">
              <TextInput
                type="date"
                name="enddate"
                label="End Date: "
                note={endDateNote}
                error={errors.enddate}
                value={experience.enddate}
                disabled={experience.noend}
                onChange={onChange}/>
            </div>
            <div className="col-lg-12">
              <div className="form-check mb-3">
                <input
                  name="noend"
                  checked={experience.noend}
                  className="form-check-input"
                  type="checkbox"
                  onChange={onChange}/>
                <label className="form-check-label">Check if present</label>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <hr/>
        </div>

        <div className="col-lg-12 mb-4">
          <label className="form-control-label">Is this experience part of a course or program?</label>
          <span className={classNames(
            'note',
            'pl-1',
            'Required',
            {
              required: errors && errors.courseProgramAffiliated && errors.courseProgramAffiliated.length > 0
            }
          )}>(Required)</span>
          <Select
            name="courseProgramAffiliated"
            placeholder="Select type that best fits"
            onChange={onChangeSelectDropdown}
            value={experience.courseProgramAffiliated}
            options={selectOptions}/>

          {errors.courseProgramAffiliated &&
            <div className="text-danger">{errors.courseProgramAffiliated}</div>}
        </div>
        <div className="col-lg-6">
          <CSSTransition
            in={(
              _isObject(experience.courseProgramAffiliated) &&
                experience.courseProgramAffiliated.value === 'Yes'
            )}
            timeout={400}
            classNames={transitionClasses}
            unmountOnExit>
            <TextInput
              type="number"
              name="reportedHours"
              label={reportedHoursLabel}
              error={errors.reportedHours}
              value={experience.reportedHours}
              onChange={onChange}/>
          </CSSTransition>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="action-bar text-right">
            {! _isUndefined(isDeleting) && showDelete &&
              <Button
                type="submit"
                onClick={onDelete}
                styles={{marginLeft: 0}}
                disabled={isDeleting}
                buttonClass="btn-danger"
                buttonText={isDeleting ? 'Deleting...' : 'Delete'}/>}

            <Button
              type="button"
              onClick={onCancel}
              buttonClass="btn-secondary"
              buttonText="Cancel"/>

            <Button
              type="submit"
              onClick={onSave}
              disabled={isSubmitting || isUpdating}
              buttonClass="btn-primary"
              buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>
          </div>
        </div>
      </div>
    </div>
  );
};

ExperienceForm.propTypes = {
  showDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  isUpdating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  experience: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChangeDescription: PropTypes.func,
  onChangeSelectDropdown: PropTypes.func.isRequired
};

export default ExperienceForm;
